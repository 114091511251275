import NewsSection from "../components/sections/news"
import AboutSection from "../components/sections/about"
import React, { FC } from "react"
import BasicLayout from "../layout/BasicLayout"
import DarkBetweenSections from "../components/sections/DarkBetweenSections"
import StorySection from "..//components/sections/story"
import NonworldSection from "../components/sections/nonworld"
import FaqSection from "../components/sections/faq"
import MediaSection from "../components/sections/media"
import CommunitySection from "../components/sections/community"

import "swiper/css"
import "swiper/css/pagination"
import { graphql } from "gatsby"

interface IMainPageProps {
  className?: string
}

const MainPage: FC<IMainPageProps> = ({ className }) => {
  return (
    <BasicLayout>
      <div className={`${className}`}>
        <NewsSection />
        <DarkBetweenSections />
        <AboutSection />
        <DarkBetweenSections className="block" />
        <a
          id="story-mobile"
          className="invisible top-64 relative block w-full h-0.5"
        ></a>
        <StorySection />
        <DarkBetweenSections />
        <a
          id="nonworld-mobile"
          className="invisible top-48 relative block w-full h-0.5"
        ></a>
        <NonworldSection />
        <DarkBetweenSections className="hidden md:block" />
        <a
          id="faq-mobile"
          className="invisible top-48 relative block w-full h-0.5"
        ></a>
        <FaqSection />
        <DarkBetweenSections className="hidden md:block" />
        <a
          id="media-mobile"
          className="invisible top-12 relative block w-full h-0.5"
        ></a>
        <MediaSection />
        <DarkBetweenSections className="hidden md:block" />
        <a
          id="community-mobile"
          className="invisible top-96 relative block w-full h-0.5"
        ></a>
        <CommunitySection />
      </div>
    </BasicLayout>
  )
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

MainPage.defaultProps = {
  className: "",
}

export default MainPage
