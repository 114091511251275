import React, { FC } from "react"
import fromLeftToRightLine from "../../assets/images/from-right-to-left-line.png"
import facebookSvg from "../../assets/images/footer/facebook.svg"
import twitterSvg from "../../assets/images/footer/twitter.svg"
import discordSvg from "../../assets/images/footer/discord.svg"
import youtubeSvg from "../../assets/images/footer/youtube.svg"
import dustLogoSvg from "../../assets/images/the-dust-logo.svg"

interface IFooterProps {
  className?: string
}

const Footer: FC<IFooterProps> = ({ className }) => (
  <div className={`${className} bottom-0 bg-black z-10`}>
    <div className="container mx-auto pt-2 pb-4 relative">
      <div className="flex justify-between">
        <div className="mb-2">
          <img
            src={fromLeftToRightLine}
            alt=""
            className="ml-0 mr-auto rotate-180 block"
          />
        </div>
        <div>
          <img
            src={fromLeftToRightLine}
            alt=""
            className="ml-0 mr-auto block"
          />
        </div>
      </div>
      <div className="flex justify-left md:justify-between items-center flex-wrap md:flex-nowrap">
        <a href="https://www.thedust.pl/" target="_blank" rel="noreferrer">
          <img src={dustLogoSvg} alt="THE DUST" className="mb-8 md:mb-0" />
        </a>
        <p
          style={{ fontSize: "10px" }}
          className="leading-none md:px-6 mb-8 md:mb-0"
        >
          © 2022 The Dust S.A. All rights reserved. The Inquisitor and The Dust
          logo are registered or unregistered trademarks of The Dust S.A. in the
          U.S. and/or other countries.
        </p>
        <div className="flex flex-shrink-0 justify-center w-full md:w-auto">
          <a
            className="transition duration-700 md:hover:scale-150"
            href="https://twitter.com/Inquisitor_game"
            target="_blank"
            rel="noreferrer"
          >
            <img src={twitterSvg} alt="Twitter" className="w-5" />
          </a>
          <a
            href="https://www.facebook.com/TheInquisitor.game"
            target="_blank"
            rel="noreferrer"
            className="ml-4 transition duration-700 md:hover:scale-150"
          >
            <img src={facebookSvg} alt="Facebook" className="w-5" />
          </a>
          <a
            href="https://discord.gg/uexaVXqwrh"
            target="_blank"
            rel="noreferrer"
            className="ml-4 transition duration-700 md:hover:scale-150"
          >
            <img src={discordSvg} alt="Discord" className="w-6" />
          </a>
          <a
            href="https://www.youtube.com/c/TheDustStudio"
            target="_blank"
            rel="noreferrer"
            className="ml-4 transition duration-700 md:hover:scale-150"
          >
            <img src={youtubeSvg} alt="Youtube" className="w-5" />
          </a>
        </div>
      </div>
    </div>
  </div>
)

Footer.defaultProps = {
  className: "",
}

export default Footer
