import { replaceAnchorInUrlBySections, useIsMdScreen } from "../helpers/layout"
import React, { FC, useEffect } from "react"
import Footer from "../components/footer"
import Header from "../components/header"
import layoutStore from "./layoutStore"
import SideMenu from "../components/side-menu"
import CookiesBanner from "../components/cookies-banner"
import AgeOverlay from "../components/AgeOverlay"

interface IBasicLayoutProps {
  className?: string
  children: JSX.Element | JSX.Element[]
}

const BasicLayout: FC<IBasicLayoutProps> = ({ className, children }) => {
  const isMd = useIsMdScreen()
  const setCurrentAnchor = layoutStore((state) => state.setCurrentAnchor)

  const lazyAnchorUpdating = () => {
    replaceAnchorInUrlBySections()
    setCurrentAnchor(window.location.href)
  }

  useEffect(() => {
    lazyAnchorUpdating()
    window.addEventListener("scroll", lazyAnchorUpdating)

    return () => {
      window.removeEventListener("scroll", lazyAnchorUpdating)
    }
  })

  const ResponsiveFooter = () => {
    if (isMd) {
      return (
        <div className="sticky bottom-0 z-20">
          <CookiesBanner className="" />
          <Footer />
        </div>
      )
    }

    return (
      <>
        <CookiesBanner className="sticky bottom-0  z-20" />
        <Footer />
      </>
    )
  }

  return (
    <>
      <div
        className={`${className} bg-black text-white font-faucheur text-lg max-w-full`}
      >
        <Header className="" />
        <AgeOverlay />
        <main className="">{children}</main>
        <SideMenu />
        <ResponsiveFooter />
      </div>
      <div id="modal-container"></div>
    </>
  )
}

BasicLayout.defaultProps = {
  className: "",
}

export default BasicLayout
