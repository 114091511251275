import React, { FC } from "react"
import { SectionTypes } from "./media-store"
import mediaStore from "./media-store"
import Video from "./video"
import Others from "./others"
import Graphics from "./graphics"

interface IContentSwitcherProps {
  className?: string
}

const IS_SINGLE_MOVIE = true

const ContentSwitcher: FC<IContentSwitcherProps> = ({ className }) => {
  const currentSection = mediaStore((state) => state.currentSection)

  const SingleMovie = () => (
    <iframe
      className="w-full h-[300px] sm:h-[400px] lg:h-[660px]"
      width="100%"
      height="720"
      src="https://www.youtube.com/embed/QV-kMz_FL4M"
      title="YouTube video player"
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></iframe>
  )

  const getVideo = () => {
    if (IS_SINGLE_MOVIE) {
      return <SingleMovie />
    }

    return <Video />
  }

  const getContent = () => {
    switch (currentSection) {
      case SectionTypes.video:
        return getVideo()
      case SectionTypes.graphics:
        return <Graphics />
      case SectionTypes.others:
        return <Others />

      default:
        return getVideo()
    }
  }

  return (
    <div
      className={`${className} flex items-start min-h-[490px] md:min-h-[970px] justify-center pt-12`}
    >
      {getContent()}
    </div>
  )
}

ContentSwitcher.defaultProps = {
  className: "",
}

export default ContentSwitcher
