import { I18nextContext, useI18next } from "gatsby-plugin-react-i18next"
import React, { FC, useEffect, useRef, useState } from "react"
import selectArrowSvg from "../assets/images/select-arrow.svg"
import selectDivider from "../assets/images/select-divider.svg"
import selectShadow from "../assets/images/select-smoke.png"

interface ILanguageSwitcherProps {
  className?: string
}

const LanguageSwitcher: FC<ILanguageSwitcherProps> = ({ className }) => {
  const languageContext = React.useContext(I18nextContext)
  const [isOpen, setIsOpen] = useState(false)
  const { changeLanguage } = useI18next()
  const selectRef = useRef(null as null | HTMLDivElement)

  const getLanguageOptions = () =>
    languageContext.languages
      .filter((lng) => lng !== languageContext.language)
      .map((lng) => {
        return (
          <button
            key={lng}
            onClick={() => handleOptionClick(lng)}
            className="uppercase block w-full text-center"
          >
            {lng}
          </button>
        )
      })

  const handleSelectClick = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (language: string) => {
    changeLanguage(language)
  }

  const handleDocumentClick = (event: MouseEvent) => {
    const isClickedOutside =
      selectRef.current && !selectRef.current.contains(event.target as Node)

    if (isClickedOutside) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)

    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  return (
    <div className={`${className} relative`} ref={selectRef}>
      <button className="uppercase relative z-20" onClick={handleSelectClick}>
        <div className="flex items-center justify-center">
          <span>{languageContext.language}</span>
          <img
            src={selectArrowSvg}
            className={`ml-1 transition duration-500 ${
              isOpen ? "rotate-180" : ""
            }`}
            alt="arrow"
          />
        </div>
        <img src={selectDivider} alt="" />
      </button>
      <div
        className={`
        absolute left-0 right-0 z-10 mt-1 transition-all overflow-hidden duration-500 text-center
        
        `}
        style={{ maxHeight: isOpen ? "100px" : "0px" }}
      >
        {getLanguageOptions()}
      </div>
      <img
        src={selectShadow}
        alt=""
        className={`hidden lg:block absolute bottom-0 left-1/2 -translate-x-1/2 transition-all duration-500 ${
          isOpen ? "opacity-1" : "opacity-0"
        }`}
        style={{
          width: "162px",
          maxWidth: "none",
          bottom: isOpen ? "-70px" : "0px",
        }}
      />
    </div>
  )
}

LanguageSwitcher.defaultProps = {
  className: "",
}

export default LanguageSwitcher
