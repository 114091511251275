import { getUrlByFileId } from "../../../../helpers/directus"
import React, { FC } from "react"
import { IGraphic } from "."
import { I18nextContext, useTranslation } from "gatsby-plugin-react-i18next"
import { getTranslationByLngCode } from "../../../../helpers/i18n"
import downloadBg from "../../../../assets/images/download-btn-bg.svg"
import BuyNowBgSvg from "../../../../assets/images/buy-now-btn-bg.png"

interface ISingleGraphicTeaserProps {
  className?: string
  graphic: IGraphic
}

const SingleGraphicTeaser: FC<ISingleGraphicTeaserProps> = ({
  className,
  graphic,
}) => {
  const { t } = useTranslation("media-graphics")
  const languageContext = React.useContext(I18nextContext)

  const translation = getTranslationByLngCode(
    graphic.translations,
    languageContext.language
  )

  const getDownloadUrl = () => {
    if (graphic.file_to_download) {
      getUrlByFileId(graphic.file_to_download?.filename_disk)
    }

    return graphic.redirect_link
  }

  const getDownloadButton = () => {
    if (!graphic.file_to_download && !graphic.redirect_link) {
      return <></>
    }

    return (
      <a
        className="inline-block relative text-black hover:text-white"
        target="_blank"
        href={getDownloadUrl()}
        rel="noreferrer"
      >
        <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 whitespace-nowrap leading-none inline-block mt-[1px]">
          {t("DOWNLOAD")}
        </span>
        <img
          src={BuyNowBgSvg}
          alt={t("DOWNLOAD")}
          className="block"
          style={{ width: 140, height: 45 }}
        />
      </a>
    )
  }

  return (
    <div className={`${className}`}>
      <img
        src={getUrlByFileId(graphic.image?.filename_disk)}
        alt={translation.title}
        style={{ boxShadow: "0 0 30px #000" }}
        className="shadow-2xl max-w-[372px] w-full  mx-auto"
      />
      <h4
        className="text-xl font-chamberi mt-4 text-center w-full"
        style={{ textShadow: "0 1px 1px #000" }}
      >
        {translation.title}
      </h4>
      <div className="text-center mt-4">{getDownloadButton()}</div>
    </div>
  )
}

SingleGraphicTeaser.defaultProps = {
  className: "",
}

export default SingleGraphicTeaser
