import { getUrlByFileId } from "../../../helpers/directus"
import React, { FC, useState } from "react"
import horizontalLineSvg from "../../../assets/images/horizontal-line.svg"
import croosUnderlineSvg from "../../../assets/images/cross-underline.svg"
import selectArrowSvg from "../../../assets/images/select-arrow.svg"
import { getTranslationByLngCode, ITranslation } from "../../../helpers/i18n"
import { I18nextContext } from "gatsby-plugin-react-i18next"

interface ISingleNewsProps {
  className?: string
  news: INews
  style?: React.CSSProperties
}

interface INewsTranslationFields {
  title: string
  description: string
  link?: string
}

export interface INews {
  id: string
  image: {
    filename_disk: string
    title: string
  }
  translations: ITranslation<INewsTranslationFields>[]
}

const SingleNews: FC<ISingleNewsProps> = ({ className, news, style }) => {
  const languageContext = React.useContext(I18nextContext)
  const translations = getTranslationByLngCode(
    news.translations,
    languageContext.language
  )

  const [isHover, setIsHover] = useState(false)
  const getMainContent = () => (
    <div
      className={`${className} transition duration-500 pt-12 ${
        isHover ? "-translate-y-10" : ""
      }`}
      style={{ ...style, textShadow: "0 0 3px #000" }}
      onMouseOver={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
    >
      <img
        className="w-full"
        src={getUrlByFileId(news.image.filename_disk)}
        alt={news.image.title}
      />
      <h4 className="text-left font-chamberi text-2xl md:leading-relaxed my-3">
        {translations.title}
      </h4>
      <img src={horizontalLineSvg} alt="" className="w-full" />
      <p className="mt-3" style={{ textShadow: "0 1px 1px #000" }}>
        {translations.description}
      </p>
      <div className={`text-center relative `}>
        <img
          src={croosUnderlineSvg}
          alt=""
          className={`inline-block transition duration-500 ${
            isHover ? "" : "opacity-0"
          }`}
        />
        <img
          src={selectArrowSvg}
          alt=""
          className={`inline-block absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 transition duration-500 ${
            isHover ? "opacity-0" : ""
          }`}
        />
      </div>
    </div>
  )
  if (translations.link) {
    return (
      <a href={translations.link} target="_blank" rel="noreferrer">
        {getMainContent()}
      </a>
    )
  }

  return getMainContent()
}

SingleNews.defaultProps = {
  className: "",
}

export default SingleNews
