import { isBrowser } from "../../../helpers/layout"
import React, { FC, useEffect, useRef } from "react"
import ReactDOM from "react-dom"

interface IPopupProps {
  className?: string
  isOpen: boolean
  onVisiblityChange: (isVisible: boolean) => void
}

const Popup: FC<IPopupProps> = ({
  className,
  children,
  onVisiblityChange,
  isOpen,
}) => {
  const popUpRef = useRef(null as null | HTMLDivElement)

  const handleDocumentClick = (event: MouseEvent) => {
    if (!popUpRef || !event.target) {
      return
    }

    const target = event.target as HTMLElement
    const isClickedOutside = target.id === "popup-overlay"

    if (isClickedOutside) {
      onVisiblityChange(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)

    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  const Modal = () => {
    return (
      <div
        id="popup-overlay"
        className={`${className} z-50 fixed inset-0 py-24 md:p-48 bg-[#000000aa] w-100vw`}
      >
        <div className="w-full h-full relative" ref={popUpRef}>
          <button
            className="absolute right-8 top-4 text-6xl text-white font-faucheur"
            onClick={() => onVisiblityChange(false)}
          >
            X
          </button>
          {children}
        </div>
      </div>
    )
  }

  if (!isOpen || !isBrowser) {
    return <></>
  }

  const modalContainer = document.getElementById(
    "modal-container"
  ) as HTMLElement

  return ReactDOM.createPortal(<Modal />, modalContainer)
}

Popup.defaultProps = {
  className: "",
}

export default Popup
