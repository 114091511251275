import { t } from "i18next"
import React, { FC, useEffect, useRef, useState } from "react"
import selectArrowSvg from "../../assets/images/select-arrow.svg"
import selectBorder from "../../assets/images/select-border.svg"

export interface IOption {
  value: any
  label: string
}

interface ISelectProps {
  className?: string
  onChange: (option: IOption) => void
  options: IOption[]
  value: IOption | null
}

const Select: FC<ISelectProps> = ({ className, onChange, options, value }) => {
  const [isOpen, setIsOpen] = useState(false)
  const selectRef = useRef(null as null | HTMLDivElement)

  const handleSelectClick = () => {
    setIsOpen(!isOpen)
  }

  const handleOptionClick = (option: IOption) => {
    setIsOpen(false)
    onChange(option)
  }

  const getOptions = () =>
    options.map((option) => {
      return (
        <button
          key={option.label}
          onClick={() => handleOptionClick(option)}
          className="uppercase block w-full text-center hover:text-primaryActive transiation duration-500 px-4 min-w-[128px]"
        >
          {option.label}
        </button>
      )
    })

  const handleDocumentClick = (event: MouseEvent) => {
    const isClickedOutside =
      selectRef.current && !selectRef.current.contains(event.target as Node)

    if (isClickedOutside) {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleDocumentClick)

    return () => {
      document.removeEventListener("click", handleDocumentClick)
    }
  }, [])

  return (
    <div className={`${className} relative inline-block`} ref={selectRef}>
      <button className="uppercase relative z-10" onClick={handleSelectClick}>
        <div className="flex items-center justify-between mx-6 px-2 min-w-[130px]">
          <span>{value?.label || t("Day")}</span>
          <img
            src={selectArrowSvg}
            className={`ml-1 transition duration-500 w-4 ${
              isOpen ? "rotate-180" : ""
            }`}
            alt="arrow"
          />
        </div>
        <div className="absolute left-0 right-0 -top-2 -bottom-2 block">
          <img
            src={selectBorder}
            alt=""
            className="w-full h-full max-w-none max-h-none"
          />
        </div>
      </button>
      <div
        className={`
        absolute left-1/2 mt-3 -translate-x-1/2 z-20 transition-all overflow-auto duration-500 text-center thin-scroll bg-black
        
        `}
        style={{ maxHeight: isOpen ? "160px" : "0px" }}
      >
        <div className="pt-2"></div>
        {getOptions()}
        <div className="pb-2"></div>
      </div>
    </div>
  )
}

Select.defaultProps = {
  className: "",
}

export default Select
