import Popup from "../../../../components/ui/popup"
import React, { FC, useState } from "react"
import { IVideo } from "."
import { getUrlByFileId } from "../../../../helpers/directus"
import playButtonSvg from "../../../../assets/images/play-btn.svg"
import { I18nextContext } from "gatsby-plugin-react-i18next"
import { getTranslationByLngCode } from "../../../../helpers/i18n"

interface ISingleVideoTeaserProps {
  className?: string
  video: IVideo
}

const SingleVideoTeaser: FC<ISingleVideoTeaserProps> = ({
  className,
  video,
}) => {
  const languageContext = React.useContext(I18nextContext)
  const [isOpenPopup, setIsOpenPopup] = useState(false)

  const translation = getTranslationByLngCode(
    video.translations,
    languageContext.language
  )

  return (
    <button className={`${className}`}>
      <div className="relative">
        <Popup
          isOpen={isOpenPopup}
          onVisiblityChange={(isVisible) => setIsOpenPopup(isVisible)}
        >
          <iframe
            className="w-full h-64 absolute top-1/2 -translate-y-1/2 sm:h-full sm:static sm:top-0 sm:translate-y-0"
            title="YouTube video player"
            src={`https://www.youtube.com/embed/${video.video_id}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Popup>
        <div className="relative inline-block">
          <img
            src={getUrlByFileId(video.thumbnail.filename_disk)}
            style={{ boxShadow: "0 0 30px #000" }}
            alt=""
            className="shadow-2xl max-w-[372px] w-full mx-auto"
          />
          <img
            src={playButtonSvg}
            alt="play"
            className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2"
          />
        </div>
        <div
          className="absolute inset-0"
          onClick={() => setIsOpenPopup(true)}
        ></div>
      </div>
      <h4
        className="text-xl font-chamberi"
        style={{ textShadow: "0 1px 1px #000" }}
      >
        {translation?.title}
      </h4>
    </button>
  )
}

SingleVideoTeaser.defaultProps = {
  className: "",
}

export default SingleVideoTeaser
