import { ITranslation } from "../../../../helpers/i18n"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import SingleGraphicTeaser from "../graphics/SingleGraphicTeaser"
import { useIsMdScreen } from "../../../../helpers/layout"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper"
interface IOthersProps {
  className?: string
}

interface IOtherTranslationFields {
  title: string
}

export interface IOther {
  id: string
  sort: number | null
  image: {
    filename_disk: string
  }
  file_to_download: {
    filename_disk: string
  } | null
  redirect_link: string
  translations: ITranslation<IOtherTranslationFields>[]
}

interface IDirectusOthersData {
  directus: {
    Others: IOther[]
  }
}

const Others: FC<IOthersProps> = ({ className }) => {
  const isMd = useIsMdScreen()
  const {
    directus: { Others },
  } = useStaticQuery<IDirectusOthersData>(graphql`
    query {
      directus {
        Others {
          id
          sort
          image {
            filename_disk
          }
          file_to_download {
            filename_disk
          }
          redirect_link
          translations {
            title
            languages_code {
              code
            }
          }
        }
      }
    }
  `)

  const getOtherTeasers = () =>
    Others.map((item) => (
      <SingleGraphicTeaser
        key={item.id}
        graphic={item}
        className="mx-6 mt-12"
      />
    ))

  const getOtherSlides = () =>
    Others.map((item) => (
      <SwiperSlide key={item.id}>
        <SingleGraphicTeaser graphic={item} className="mx-6 mt-12" />
      </SwiperSlide>
    ))

  const mobileVersion = () => (
    <Swiper
      className={`${className} mb-24`}
      modules={[Autoplay, Pagination]}
      spaceBetween={50}
      autoplay={{
        delay: 5000,
      }}
      pagination={{
        el: ".media-pagination",
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        520: {
          slidesPerView: Math.min(2, Others.length),
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: Math.min(3, Others.length),
          spaceBetween: 30,
        },
      }}
    >
      {getOtherSlides()}
      <div className="media-pagination text-center flex justify-center mt-6 w-full"></div>
    </Swiper>
  )

  const desktopVersion = () => (
    <div className={`${className}`}>
      <div className="flex justify-center flex-wrap">{getOtherTeasers()}</div>
    </div>
  )

  if (isMd || isMd === null) {
    return desktopVersion()
  }

  return mobileVersion()
}

Others.defaultProps = {
  className: "",
}

export default Others
