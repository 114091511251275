import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import SingleNews, { INews } from "./SingleNews"
import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation } from "swiper"
import selectArrowSvg from "../../../assets/images/select-arrow.svg"

interface INewsSliderProps {
  className?: string
}

const NewsSlider: FC<INewsSliderProps> = ({ className }) => {
  const data = useStaticQuery(graphql`
    query {
      directus {
        news(sort: ["-id"]) {
          id
          image {
            filename_disk
            title
          }
          translations {
            title
            description
            link
            languages_code {
              code
            }
          }
        }
      }
    }
  `)

  const { news } = data.directus

  const getNewsSlides = () =>
    news.map((singleNews: INews) => {
      return (
        <SwiperSlide key={singleNews.id}>
          <SingleNews
            news={singleNews}
            key={news.id}
            className={`flex-shrink-0`}
            style={{ width: "100%" }}
          />
        </SwiperSlide>
      )
    })

  return (
    <div className={`${className} container mx-auto`}>
      <div className="">
        <Swiper
          style={{ maxWidth: 1150 }}
          modules={[Autoplay, Pagination, Navigation]}
          spaceBetween={50}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          autoplay={{
            delay: 5000,
          }}
          pagination={{
            type: "fraction",
            el: ".custom-pagination",
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            520: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
          }}
        >
          {getNewsSlides()}
          <div className="text-center mt-6 relative">
            <div className="custom-pagination text-3xl"></div>
            <button className="swiper-button-prev absolute left-1/2 -translate-x-20 top-1/2 -translate-y-1/2">
              <img src={selectArrowSvg} alt="" className="w-6 rotate-90" />
            </button>
            <button className="swiper-button-next absolute left-1/2 translate-x-12 top-1/2 -translate-y-1/2">
              <img src={selectArrowSvg} alt="" className="w-6 -rotate-90" />
            </button>
          </div>
        </Swiper>
      </div>
    </div>
  )
}

NewsSlider.defaultProps = {
  className: "",
}

export default NewsSlider
