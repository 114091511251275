import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import fromLeftToRightLine from "../../../assets/images/from-right-to-left-line.png"

interface INonworldSectionProps {
  className?: string
}

const NonworldSection: FC<INonworldSectionProps> = ({ className }) => {
  const { t } = useTranslation("nonworld-section")

  return (
    <section
      id="nonworld"
      className={`${className} bg-no-repeat bg-nonworldMobile md:bg-nonworld pt-32 sm:py-72 overflow-hidden bg-[length:1210px_auto] bg-[25%_top] md:bg-center md:bg-cover relative`}
      style={{ textShadow: "0 3px 3px #000" }}
    >
      <div
        id="nonworld-anchor-desktop"
        className="relative -top-72 desktop-anchor"
      ></div>
      <div className="container mx-auto flex">
        <div className="lg:w-9/12 xl:w-7/12 2xl:w-6/12 flex items-end flex-col-reverse ml-auto mr-0">
          <div className="relative">
            <div
              className="absolute -inset-32 "
              style={{
                background:
                  "radial-gradient(circle, rgba(0,0,0,1) -60%, rgba(0,0,0,0) 50%)",
              }}
            ></div>
            <div className="relative z-10">
              <h2 className="font-chamberi text-5xl md:text-8xl lg:text-10xl mb-5 text-left mt-128 md:mt-0">
                {t("Unworld")}
              </h2>
              <img
                src={fromLeftToRightLine}
                alt=""
                className="ml-0 mr-auto rotate-180 my-4"
              />
              <p className="text-left leading-tight">
                {t(
                  `Through his prayers, the Inquisitor frees his soul from his body and enters the abyss called the Unworld. There he sees concentrations of magic and learns the true nature of beings. The price for using this ability is unimaginable pain. It’s a very dangerous and hard to navigate place where fighting for your life will be inevitable. In the Unworld, some monsters lurk in the soul, but they do not have specific shapes. They look like figures woven from Black Smoke. Only prayer protects the Inquisitor's soul from their attacks. `
                )}
              </p>
              <img
                src={fromLeftToRightLine}
                alt=""
                className="ml-0 mr-auto rotate-180 my-4"
              />
              <p className="text-left mt-5 leading-tight mb-16">
                {t(
                  `It is a very dangerous place. For the guardian of the Unworld is the Darkness which cannot be defeated in any way. Only prayer and unwavering faith can provide protection against it.`
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

NonworldSection.defaultProps = {
  className: "",
}

export default NonworldSection
