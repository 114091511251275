import create from "zustand"

type LayoutStore = {
  currentUrl: string | null
  setCurrentAnchor: (url: string) => void
}

const layoutStore = create<LayoutStore>((set) => ({
  currentUrl: null,

  setCurrentAnchor: (url) => set(() => ({ currentUrl: url })),
}))

export default layoutStore
