const OLDEST_PERSON = 120

export const getYears = () => {
  const currentYear = new Date().getFullYear()

  return new Array(OLDEST_PERSON).fill(null).map((item, index) => {
    const year = currentYear - index

    return { label: `${year}`, value: year }
  })
}
