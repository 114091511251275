import React, { FC } from "react"

interface ISectionTitleProps {
  className?: string
  title: string
}

const SectionTitle: FC<ISectionTitleProps> = ({ className, title }) => (
  <div className={`${className}`}>
    <h4
      className="text-center text-[40px] font-chamberi leading-relaxed mb-4 relative z-20"
      style={{ textShadow: "0 1px 1px #000" }}
    >
      {title}
    </h4>
  </div>
)

SectionTitle.defaultProps = {
  className: "",
}

export default SectionTitle
