import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import BuyNowBgSvg from "../../assets/images/buy-now-btn-bg.png"

interface IBuyNowButtonProps {
  className?: string
}

const BuyNowButton: FC<IBuyNowButtonProps> = ({ className }) => {
  const { t } = useTranslation("header")

  return (
    <div className="relative flex items-center justify-center">
      <a
        href="https://www.kalypsomedia.com/us/games/the-inquisitor/"
        target="_blank"
        rel="noreferrer"
        className={`${className} relative text-black hover:text-white transition-colors duration-500`}
        type="button"
      >
        <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 whitespace-nowrap leading-none inline-block mt-[1px]">
          {t("BUY NOW")}
        </span>
        <img
          src={BuyNowBgSvg}
          alt={t("BUY NOW")}
          className="block"
          style={{ width: 140, height: 45 }}
        />
      </a>
    </div>
  )
}

BuyNowButton.defaultProps = {
  className: "",
}

export default BuyNowButton
