import React, { FC, useEffect } from "react"
import MenuLink from "./MenuLink"
import { Helmet, useTranslation } from "gatsby-plugin-react-i18next"
import Logo from "./Logo"
import BuyNowButton from "./BuyNowButton"
import LanguageSwitcher from "../LanguageSwitcher"
import bottomShadow from "../../assets/images/mobile-top-smoke.png"
import OpenMenuButton from "../side-menu/OpenMenuButton"
import { smokeAnimation } from "../../helpers/smoke-animation"
import { useIsLgScreen, isLgScreen } from "../../helpers/layout"
interface IHeaderProps {
  className?: string
}

const Header: FC<IHeaderProps> = ({ className }) => {
  const { t } = useTranslation("header")
  const isLg = useIsLgScreen()

  const DesktopVersion = () => (
    <nav className="hidden lg:flex w-full py-6 mx-auto items-center relative px-12 max-w-[1700px]">
      <div className="w-1/3 flex justify-between">
        <MenuLink name={t("News")} sectionName="news" anchor="news" />
        <MenuLink
          name={t("About")}
          sectionName="about-game"
          anchor="about-game-anchor-desktop"
        />
        <MenuLink
          name={t("Story")}
          sectionName="story"
          anchor="story-anchor-desktop"
        />
        <MenuLink
          name={t("Nonworld")}
          sectionName="nonworld"
          anchor="nonworld-anchor-desktop"
        />
        <MenuLink
          name={t("FAQ")}
          sectionName="faq"
          anchor="faq-anchor-desktop"
        />
      </div>
      <div className="w-1/3 flex justify-center">
        <a href="#news">
          <Logo />
        </a>
      </div>
      <div className="w-1/3 flex justify-between items-center">
        <MenuLink
          name={t("Media")}
          sectionName="media"
          anchor="media-anchor-desktop"
        />
        <MenuLink
          name={t("Community")}
          anchor="community-anchor-desktop"
          sectionName="community"
        />
        <BuyNowButton className="relative z-20" />
        <LanguageSwitcher />
      </div>
    </nav>
  )

  const MobileVersion = () => (
    <nav className="w-full py-4 flex lg:hidden container mx-auto items-center">
      <img
        src={bottomShadow}
        alt=""
        className="left-0 -top-6 sm:-top-20 w-screen absolute  max-w-[1925px] hidden"
      />
      <div className="text-center w-full z-10 relative">
        <a href="#news">
          <Logo className="w-[136px] block mx-auto" />
        </a>
        <OpenMenuButton className="absolute right-0 top-1/2 -translate-y-1/2" />
      </div>
    </nav>
  )

  const animate = () => {
    const isSmokeAnimationTurnedOn = false

    if (!isSmokeAnimationTurnedOn) {
      return () => true
    }

    return isLgScreen() ? smokeAnimation() : () => true
  }

  useEffect(() => {
    const cleaner = animate()

    const windowResizeHandler = () => {
      cleaner()
      animate()
    }

    window.addEventListener("resize", windowResizeHandler)

    return () => {
      window.removeEventListener("resize", windowResizeHandler)
      cleaner()
    }
  }, [isLg])

  return (
    <>
      <Helmet>
        <title>The Inquisitor</title>
        <meta name="description" content={t("metaDescription")}></meta>
      </Helmet>
      <div id="header-placeholder" className="pointer-events-none">
        <canvas
          id="smoke"
          className="-translate-y-[700px] inset-0 fixed top-0 left-0 right-0 z-20 h-screen pointer-events-none"
        ></canvas>
      </div>
      <header
        className={`${className} bg-black fixed z-20 top-0 left-0 right-0 overflow-y-visible overflow-x-clip lg:min-h-[110px]`}
        id="header"
      >
        <div className="z-20 sticky top-0">
          <DesktopVersion />
          <MobileVersion />
        </div>
      </header>
    </>
  )
}

Header.defaultProps = {
  className: "",
}

export default Header
