export type ITranslation<Fields> = Fields & {
  languages_code: {
    code: string
  }
}

export const getTranslationByLngCode = <TranslationFields>(
  translations: ITranslation<TranslationFields>[],
  languageCode: string
): ITranslation<TranslationFields> => {
  const translate = translations.find(
    (translation) => translation.languages_code?.code === languageCode
  )

  if (!translate) {
    const englishTranslation = translations.find(
      (translation) => translation.languages_code?.code === "en"
    )

    if (!englishTranslation) {
      return translations[0]
    }

    return englishTranslation
  }

  return translate
}
