import { isBrowser } from "../../helpers/layout"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC, useState } from "react"
import downloadBg from "../../assets/images/agree-bg.png"
import BuyNowBgSvg from "../../assets/images/buy-now-btn-bg.png"

interface ICookiesBannerProps {
  className?: string
}

const CookiesBanner: FC<ICookiesBannerProps> = ({ className }) => {
  const { t } = useTranslation("cookies-banner")
  const [consent, setConset] = useState<boolean>(
    isBrowser ? !!localStorage.getItem("cookies-conset") : true
  )

  const handleAgreeClick = () => {
    localStorage.setItem("cookies-conset", "true")
    setConset(true)
  }

  return (
    <div
      className={`${className} duration-500 overflow-hidden bg-black z-20 ${
        consent ? "max-h-0" : "max-h-96"
      }`}
    >
      <div
        className={`container mx-auto flex justify-center items-center py-10 flex-wrap lg:flex-nowrap`}
      >
        <p className="mb-0 text-2xl text-center lg:text-left">
          {t(
            "This site uses cookies for visitor statistics. By continuing to browse the site you are agreeing to our use of cookies. "
          )}
          <a
            href="https://cms.theinquisitor.game/assets/08ceecb3-50f7-4dd0-ab35-3ec5a5edfaca.pdf"
            target="_blank"
            rel="noreferrer"
            className="text-primaryActive"
          >
            {t("Privacy Policy")}
          </a>
        </p>
        <button
          onClick={handleAgreeClick}
          className="relative text-black hover:text-white transition duration-500 lg:ml-12 mt-6 lg:mt-0 shrink-0"
        >
          <img
            src={BuyNowBgSvg}
            alt={t("WISHLIST")}
            className="block"
            style={{ width: 140, height: 45 }}
          />
          <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 whitespace-nowrap leading-none inline-block mt-[1px]">
            {t("AGREE")}
          </span>
        </button>
      </div>
    </div>
  )
}

CookiesBanner.defaultProps = {
  className: "",
}

export default CookiesBanner
