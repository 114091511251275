import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import mediaStore, { SectionTypes } from "./media-store"

interface ITabSelectorProps {
  className?: string
}

const TabSelector: FC<ITabSelectorProps> = ({ className }) => {
  const { t } = useTranslation("media")

  const setCurrentSection = mediaStore((state) => state.setCurrentSection)
  const currentSection = mediaStore((state) => state.currentSection)

  const getActiveClasses = (section: string) => {
    if (section !== currentSection) {
      return ""
    }

    return "text-primaryActive"
  }

  return (
    <div className={`${className} inline-block`}>
      <button onClick={() => setCurrentSection(SectionTypes.video)}>
        <h4
          className={`text-lg md:text-4xl uppercase font-chamberi mx-4 ${getActiveClasses(
            "video"
          )}`}
        >
          {t("VIDEO")}
        </h4>
      </button>
      <button onClick={() => setCurrentSection(SectionTypes.graphics)}>
        <h4
          className={`text-lg md:text-4xl uppercase font-chamberi mx-4 ${getActiveClasses(
            "graphics"
          )}`}
        >
          {t("GRAPHICS")}
        </h4>
      </button>
      <button onClick={() => setCurrentSection(SectionTypes.others)}>
        <h4
          className={`text-lg md:text-4xl uppercase font-chamberi mx-4 ${getActiveClasses(
            "others"
          )}`}
        >
          {t("Others")}
        </h4>
      </button>
    </div>
  )
}

TabSelector.defaultProps = {
  className: "",
}

export default TabSelector
