import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC, useEffect, useState } from "react"
import Logo from "./header/Logo"
import { getMonths } from "../helpers/dates/months"
import Select, { IOption } from "./ui/Select"
import { getDaysInMonth } from "../helpers/dates/days"
import { getYears } from "../helpers/dates/years"
import buttonBg from "../assets/images/buy-now-btn-bg.png"
import { isBrowser } from "../helpers/layout"

interface IAgeOverlayProps {
  className?: string
}

const MIN_AGE = 18

const AgeOverlay: FC<IAgeOverlayProps> = ({ className }) => {
  const { t } = useTranslation("age-overlay")
  const [selectedDay, setSelectedDay] = useState<IOption | null>(null)
  const [selectedMonth, setSelectedMonth] = useState<IOption | null>(null)
  const [selectedYear, setSelectedYear] = useState<IOption | null>(null)
  const [isEnterAllowed, setIsEnterAllowed] = useState<boolean | null>(true)

  const validateAge = () => {
    if (!selectedDay) {
      alert(t("You need to select day of your birth"))
      return
    }
    if (!selectedMonth) {
      alert(t("You need to select month of your birth"))
      return
    }
    if (!selectedYear) {
      alert(t("You need to select year of your birth"))
      return
    }
    const dateOfBirth = new Date(
      `${selectedMonth.value}/${selectedDay.value}/${selectedYear.value}`
    )
    const now = new Date()

    const timeDiff = now.getTime() - dateOfBirth.getTime()

    const yearsOld = new Date(timeDiff).getUTCFullYear() - 1970

    if (yearsOld < MIN_AGE) {
      setIsEnterAllowed(false)

      return
    }

    setIsEnterAllowed(true)
    localStorage.setItem("isEnterAllowed", "true")
  }

  const getErrorInfoIfAny = () => {
    if (isEnterAllowed === false) {
      return (
        <p className="my-6 text-2xl text-red-500">
          {t("We are sorry, but you are not allowed to visit this page.")}
        </p>
      )
    }
  }

  useEffect(() => {
    setIsEnterAllowed(localStorage.getItem("isEnterAllowed") ? true : null)
  }, [])

  return (
    <div
      className={`${className} fixed inset-0 z-40 bg-black bg-age bg-center bg-cover ${
        isEnterAllowed === true ? "hidden" : ""
      }`}
    >
      <div className="absolute top-1/2 -translate-y-1/2 flex items-center justify-center w-full">
        <div className="">
          <Logo className="mx-auto" />
          <h4 className="mt-16 mb-12 text-3xl text-center">
            {t("Enter your date of birth")}
          </h4>
          <div className="flex flex-wrap md:flex-nowrap">
            <div className="w-full text-center">
              <Select
                className="mb-12"
                options={getDaysInMonth()}
                onChange={setSelectedDay}
                value={selectedDay || { value: null, label: t("Day") }}
              />
            </div>
            <div className="w-full text-center">
              <Select
                className="mb-12"
                options={getMonths(t)}
                onChange={setSelectedMonth}
                value={selectedMonth || { value: null, label: t("Month") }}
              />
            </div>
            <div className="w-full text-center">
              <Select
                options={getYears()}
                onChange={setSelectedYear}
                value={selectedYear || { value: null, label: t("Year") }}
              />
            </div>
          </div>
          <div className="text-center mt-12">
            {getErrorInfoIfAny()}
            <button
              onClick={validateAge}
              className={`${className} relative text-black hover:text-white transition-colors duration-500`}
              type="button"
            >
              <span className="absolute left-1/2 top-1/2 -translate-y-1/2 -translate-x-1/2 whitespace-nowrap leading-none inline-block mt-[1px]">
                {t("GO")}
              </span>
              <img
                src={buttonBg}
                alt={t("GO")}
                className="block"
                style={{ width: 140, height: 45 }}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

AgeOverlay.defaultProps = {
  className: "",
}

export default AgeOverlay
