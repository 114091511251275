import SectionTitle from "../../../components/ui/SectionTitle"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import NewsSlider from "./NewsSlider"
import TextContent from "./TextContent"

interface INewsSectionProps {
  className?: string
}

const NewsSection: FC<INewsSectionProps> = ({ className }) => {
  const { t } = useTranslation("news-section")
  return (
    <section
      className={`${className}`}
      id="news"
      style={{ textShadow: "0 3px 3px #000" }}
    >
      <div className="bg-newsMobile md:bg-news bg-[68%_top] bg-no-repeat bg-[length:auto_50%] md:bg-cover pt-64 pb-32 sm:pb-48 sm:pt-72 md:bg-center">
        <TextContent className="relative z-10" />
        <SectionTitle
          className="md:hidden mt-24 z-10 relative"
          title={t("News")}
        />
        <NewsSlider className="relative z-10" />
      </div>
    </section>
  )
}

NewsSection.defaultProps = {
  className: "",
}

export default NewsSection
