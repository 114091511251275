import React, { FC } from "react"
import logoSvg from "../../assets/images/logo.svg"

interface ILogoProps {
  className?: string
}

const Logo: FC<ILogoProps> = ({ className }) => (
  <img src={logoSvg} className={`${className}`} alt="Inquisitor" />
)

Logo.defaultProps = {
  className: "",
}

export default Logo
