import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import croosUnderlineSvg from "../../../assets/images/cross-underline.svg"

interface ITextContentProps {
  className?: string
}

const TextContent: FC<ITextContentProps> = ({ className }) => {
  const { t } = useTranslation("news-section")

  return (
    <div
      className={`${className} container max-w-[1150px] mx-auto mb-12`}
      style={{ textShadow: "0 3px 3px #000" }}
    >
      <div className="inline-block">
        <h1 className="text-left sm:text-center font-chamberi whitespace-pre-wrap text-5xl md:text-7xl leading-snug sm:leading-relaxed pr-6 sm:pr-0 inline-block sm:block">
          <span className="hidden sm:inline">
            {t("“The brave\ndon't live forever,")}
          </span>
          <span className="sm:hidden">
            {t("“The brave\ndon't live forever,")}
          </span>
        </h1>
        <h2 className="text-lg sm:text-2xl text-left sm:text-center">
          {t("but the cowards are already dead”")}
        </h2>
        <div className="flex justify-start sm:justify-center">
          <img src={croosUnderlineSvg} alt="-" />
        </div>
      </div>
    </div>
  )
}

TextContent.defaultProps = {
  className: "",
}

export default TextContent
