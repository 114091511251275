import React, { FC } from "react"
import closeIcon from "../../assets/images/close-icon.svg"
import sideMenuStore from "./side-menu-store"

interface ICloseMenuButtonProps {
  className?: string
}

const CloseMenuButton: FC<ICloseMenuButtonProps> = ({ className }) => {
  const { setIsSideMenuOpen } = sideMenuStore()

  return (
    <button className={`${className}`} onClick={() => setIsSideMenuOpen(false)}>
      <img src={closeIcon} alt="Close" />
    </button>
  )
}

CloseMenuButton.defaultProps = {
  className: "",
}

export default CloseMenuButton
