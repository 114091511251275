import { useIsMdScreen } from "../../../../helpers/layout"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import SingleVideoTeaser from "./SingleVideoTeaser"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination, Navigation } from "swiper"

interface IVideoProps {
  className?: string
}

interface IVideoTranslation {
  title: string
  languages_code: {
    code: string
  }
}
export interface IVideo {
  id: string
  sort: number | null
  title: string
  video_id: string
  thumbnail: {
    filename_disk: string
  }
  translations: IVideoTranslation[]
}

interface IDirectusVideoData {
  directus: {
    Video: IVideo[]
  }
}

const Video: FC<IVideoProps> = ({ className }) => {
  const isMd = useIsMdScreen()

  const {
    directus: { Video },
  } = useStaticQuery<IDirectusVideoData>(graphql`
    query {
      directus {
        Video {
          id
          sort
          video_id
          thumbnail {
            filename_disk
          }
          translations {
            title
            languages_code {
              code
            }
          }
        }
      }
    }
  `)

  const getVideoTeasers = () =>
    Video.map((video) => (
      <SingleVideoTeaser key={video.id} video={video} className="mx-6 mt-12" />
    ))

  const getVideoSlides = () =>
    Video.map((video) => (
      <SwiperSlide key={video.id}>
        <SingleVideoTeaser video={video} className="mx-6 mt-12" />
      </SwiperSlide>
    ))

  const desktopVersion = () => (
    <div className={`${className}`}>
      <div className="flex justify-center flex-wrap">{getVideoTeasers()}</div>
    </div>
  )

  const mobileVersion = () => (
    <Swiper
      className="mb-24"
      modules={[Autoplay, Pagination]}
      spaceBetween={50}
      autoplay={{
        delay: 5000,
      }}
      pagination={{
        el: ".media-pagination",
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
    >
      {getVideoSlides()}
      <div className="media-pagination text-center flex justify-center mt-6 w-full"></div>
    </Swiper>
  )

  if (isMd || isMd === null) {
    return desktopVersion()
  }

  return mobileVersion()
}

Video.defaultProps = {
  className: "",
}

export default Video
