import React, { FC, useState } from "react"
import fromLeftToRightLine from "../../../assets/images/from-right-to-left-line.png"
import arrowSvg from "../../../assets/images/arrow.svg"

interface ISingleFaqProps {
  className?: string
  question: string
  answer: string
}

const SingleFaq: FC<ISingleFaqProps> = ({ className, question, answer }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={`${className}`}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="relative block w-full"
      >
        <h4 className="text-lg md:text-2xl text-left font-chamberi md:cursor-auto w-full pr-12">
          {question}
        </h4>
        <img
          src={arrowSvg}
          alt=""
          className={`absolute top-1/2 -translate-y-1/2 right-5 transition duration-500 md:hidden ${
            isOpen ? "rotate-180" : ""
          }`}
        />
      </button>
      <img
        src={fromLeftToRightLine}
        alt=""
        className="ml-0 my-3 md:my-4 mr-auto rotate-180"
      />
      <p
        className={`overflow-hidden transition-all duration-700 md:max-h-fit ${
          isOpen ? "max-h-96" : "max-h-0"
        }`}
      >
        {answer}
      </p>
    </div>
  )
}

SingleFaq.defaultProps = {
  className: "",
}

export default SingleFaq
