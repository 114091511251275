import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import discordLinkPng from "../../../assets/images/community/discord.png"
import youtubeLinkPng from "../../../assets/images/community/youtube.png"
import twitterLinkPng from "../../../assets/images/community/twitter.png"
import facebookLinkPng from "../../../assets/images/community/facebook.png"
import SectionTitle from "../../../components/ui/SectionTitle"

interface ICommunitySectionProps {
  className?: string
}

const CommunitySection: FC<ICommunitySectionProps> = ({ className }) => {
  const { t } = useTranslation("community-section")

  return (
    <section
      id="community"
      className={`${className} bg-[17%_top] md:bg-center bg-communityMobile md:bg-community bg-cover py-24 md:py-0 relative`}
    >
      <div
        id="community-anchor-desktop"
        className="relative top-48 desktop-anchor"
      ></div>
      <div className="container mx-auto flex items-start md:items-center h-full justify-center lg:justify-between flex-wrap py-24 sm:py-96 2xl:py-[600px] relative z-10">
        <a
          href="https://discord.gg/uexaVXqwrh"
          target="_blank"
          className="mx-0 inline-block relative hover:scale-110 transition duration-500 md:mx-12 lg:mx-0 mb-24 lg:mb-0 w-1/2 md:w-auto"
          rel="noreferrer"
        >
          <img
            src={discordLinkPng}
            alt="Discord"
            className="max-w-[120px] mx-auto"
          />
          <p className="mb-0 md:whitespace-nowrap mt-2 text-center">
            {t("Confess your sins")}
          </p>
        </a>
        <a
          className="mx-0 inline-block relative hover:scale-110 transition duration-500 md:mx-12 lg:mx-0 mb-24 lg:mb-0 w-1/2 md:w-auto"
          target="_blank"
          href="https://www.youtube.com/c/TheDustStudio"
          rel="noreferrer"
        >
          <img
            src={youtubeLinkPng}
            alt="Youtube"
            className="max-w-[120px] mx-auto"
          />
          <p className="mb-0 md:whitespace-nowrap mt-2 text-center">
            {t("Watch trailers and gameplay")}
          </p>
        </a>
        <a
          className="mx-0 inline-block relative hover:scale-110 transition duration-500 md:mx-12 lg:mx-0 mb-24 lg:mb-0 w-1/2 md:w-auto"
          href="https://twitter.com/Inquisitor_game"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={twitterLinkPng}
            alt="Twitter"
            className="max-w-[120px] mx-auto"
          />
          <p className="mb-0 md:whitespace-nowrap mt-2 text-center">
            {t("Engage in dialogue")}
          </p>
        </a>
        <a
          className="mx-0 inline-block relative hover:scale-110 transition duration-500 md:mx-12 lg:mx-0 mb-24 lg:mb-0 w-1/2 md:w-auto"
          href="https://www.facebook.com/TheInquisitor.game"
          target="_blank"
          rel="noreferrer"
        >
          <img
            src={facebookLinkPng}
            alt="Blog"
            className="max-w-[120px] mx-auto"
          />
          <p className="mb-0 md:whitespace-nowrap mt-2 text-center">
            {t("Face the developers")}
          </p>
        </a>
      </div>
    </section>
  )
}
CommunitySection.defaultProps = {
  className: "",
}

export default CommunitySection
