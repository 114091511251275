import React, { FC } from "react"
import Logo from "../header/Logo"
import CloseMenuButton from "./CloseMenuButton"
import verticalMenuLine from "../../assets/images/vertical-menu-line.png"
import sideMenuStore from "./side-menu-store"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import BuyNowButton from "../header/BuyNowButton"
import DiscordButton from "../header/DiscrodButton"
import LanguageSwitcher from "../LanguageSwitcher"

interface ISideMenuProps {
  className?: string
}

const SideMenu: FC<ISideMenuProps> = ({ className }) => {
  const { t } = useTranslation("mobile-menu")
  const { isSideMenuOpen, setIsSideMenuOpen } = sideMenuStore()

  const MenuLink: FC<{ name: string; anchor: string }> = ({ name, anchor }) => (
    <div className="mb-10 text-center">
      <button onClick={() => setIsSideMenuOpen(false)}>
        <Link to={`/#${anchor}`} className="text-lg">
          {name}
        </Link>
      </button>
    </div>
  )

  return (
    <div
      className={`${className} fixed overflow-scroll block lg:hidden inset-0 bg-black bg-mobileMenu bg-cover z-30 p-4 transition ${
        isSideMenuOpen ? "" : "translate-x-full"
      }`}
    >
      <CloseMenuButton className="absolute top-6 right-6" />
      <div className="pb-48">
        <Logo className="w-[136px] mx-auto" />
        <img src={verticalMenuLine} alt="" className="mx-auto mt-5 mb-6" />
        <MenuLink name={t("News")} anchor="news" />
        <MenuLink name={t("About")} anchor="about-game" />
        <MenuLink name={t("Story")} anchor="story-mobile" />
        <MenuLink name={t("Nonworld")} anchor="nonworld-mobile" />
        <MenuLink name={t("FAQ")} anchor="faq-mobile" />
        <MenuLink name={t("Media")} anchor="media-mobile" />
        <MenuLink name={t("Community")} anchor="community-mobile" />
        <div className="text-center mb-10">
          <BuyNowButton className="relative z-20" />
        </div>
        <div className="flex justify-center items-center">
          <LanguageSwitcher />
        </div>
      </div>
    </div>
  )
}

SideMenu.defaultProps = {
  className: "",
}

export default SideMenu
