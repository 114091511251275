import layoutStore from "../../layout/layoutStore"
import { Link } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import croosUnderlineSvg from "../../assets/images/cross-underline.svg"

interface IMenuLinkProps {
  className?: string
  name: string
  anchor: string
  sectionName: string
}

const MenuLink: FC<IMenuLinkProps> = ({
  className,
  anchor,
  name,
  sectionName,
}) => {
  const currentUrl = layoutStore((state) => state.currentUrl)
  const currentAnchor =
    currentUrl && currentUrl.substring(currentUrl.indexOf("#") + 1)

  const isActive = () => {
    return sectionName === currentAnchor
  }

  return (
    <div className={`${className} relative`}>
      <Link
        to={`/#${anchor}`}
        style={{ opacity: isActive() ? "1" : "" }}
        className="opacity-75 hover:opacity-100 transition duration-500 hover:text-primaryActive"
      >
        {name}
      </Link>
      <img
        src={croosUnderlineSvg}
        alt="-"
        style={{ width: "107px" }}
        className={`absolute max-w-none -bottom-8 left-1/2 -translate-x-1/2 transition duration-500 ${
          isActive() ? "opacity-1" : "opacity-0"
        }`}
      />
    </div>
  )
}

MenuLink.defaultProps = {
  className: "",
}

export default MenuLink
