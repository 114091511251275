import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import fromLeftToRightLine from "../../../assets/images/from-right-to-left-line.png"
import storyPlotShadow from "../../../assets/images/top-shadow.png"

interface IStorySectionProps {
  className?: string
}

const StorySection: FC<IStorySectionProps> = ({ className }) => {
  const { t } = useTranslation("about-game-section")

  return (
    <section
      id="story"
      className={`${className} bg-no-repeat bg-storyMobile md:bg-story md:bg-cover py-32 sm:py-48 overflow-hidden relative md:bg-center bg-[length:1210px_auto]`}
      style={{ textShadow: "0 3px 3px #000", backgroundPosition: "83% -10%" }}
    >
      <div
        id="story-anchor-desktop"
        className="relative -top-44 desktop-anchor"
      ></div>
      <img
        src={storyPlotShadow}
        alt=""
        className="absolute left-0 right-0 bottom-0"
      />
      <div className="container mx-auto md:hidden mb-32 mt-72 md:mt-0">
        <p
          className="font-chamberi text-4xl mx-auto mt-6 leading-relaxed"
          style={{ maxWidth: "600px" }}
        >
          {t("“There are no bars and locks better than anxiety and fear”")}
        </p>
      </div>
      <div className="container mx-auto flex">
        <div className=" md:w-7/12 xl:w-5/12 2xl:w-6/12 relative">
          <div
            className="absolute -inset-72 "
            style={{
              background:
                "radial-gradient(circle, rgba(0,0,0,1) -75%, rgba(0,0,0,0) 75%)",
            }}
          ></div>
          <div className="relative z-10">
            <h2 className="font-chamberi text-4xl md:text-5xl lg:text-6xl md:leading-relaxed lg:leading-relaxed mb-5 md:mb-1">
              {t("Story Plot")}
            </h2>
            <img
              src={fromLeftToRightLine}
              alt=""
              className="ml-0 mr-auto rotate-180"
            />
            <p className="text-left mt-5 leading-tight">
              {t(
                `While the setting may be known to fantasy fans, the story of “I, the Inquisitor” is an original concept that we at The Dust created from scratch. Players familiar with the book series will surely appreciate the references to the original gritty, dark atmosphere that we reflected in the game.`
              )}
            </p>
            <img
              src={fromLeftToRightLine}
              alt=""
              className="ml-0 mr-auto rotate-180 my-4"
            />
            <p className="text-left mt-5 leading-tight">
              {t(
                `You are Mordimer Madderdin and you are an Inquisitor, a servant of God, working for the glory of the Holy Office in a world where the representatives of the church rule with a firm hand. Stripped of your rights after being falsely accused of killing the Emperor, you travel to the city of Köenigstein to visit your old beloved. Your plans are thwarted when you come across a mysterious murder case in which supernatural forces may be involved.`
              )}
            </p>
            <img
              src={fromLeftToRightLine}
              alt=""
              className="ml-0 mr-auto rotate-180 my-4"
            />
            <p className="text-left mt-5 leading-tight">
              {t(
                `The City of Köenigstein is on the verge of becoming an Imperial City, and its old ruler is on his deathbed. A City Council begins to form, and the local patricians fight among themselves for influence. Make sure you choose your allies wisely.`
              )}
            </p>
            <img
              src={fromLeftToRightLine}
              alt=""
              className="ml-0 mr-auto rotate-180 my-4"
            />
            <p className="text-left mt-5 leading-tight">
              {t(
                'The game "I, the Inquisitor" is inspired by Jacek Piekara\'s novels, but its story is fully original and was created by The Dust team from scratch.'
              )}
            </p>
            <img
              src={fromLeftToRightLine}
              alt=""
              className="ml-0 mr-auto rotate-180 my-4"
            />
          </div>
        </div>
      </div>
      <div className="container mx-auto hidden md:block relative z-10">
        <p
          className="font-chamberi text-3xl md:text-4xl lg:text-5xl mx-auto mt-6"
          style={{ maxWidth: "600px" }}
        >
          {t("“There are no bars and locks better than anxiety and fear”")}
        </p>
      </div>
    </section>
  )
}

StorySection.defaultProps = {
  className: "",
}

export default StorySection
