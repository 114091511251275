import React, { FC } from "react"
import HamburgerIcon from "../../assets/images/side-menu-btn.png"
import sideMenuStore from "./side-menu-store"

interface IOpenMenuButtonProps {
  className?: string
}

const OpenMenuButton: FC<IOpenMenuButtonProps> = ({ className }) => {
  const { setIsSideMenuOpen } = sideMenuStore()

  return (
    <button className={`${className}`} onClick={() => setIsSideMenuOpen(true)}>
      <img src={HamburgerIcon} alt="Open Side Menu" />
    </button>
  )
}

OpenMenuButton.defaultProps = {
  className: "",
}

export default OpenMenuButton
