import { useEffect, useState } from "react"

export const isBrowser = typeof window !== "undefined"

const IS_MOBILE_FIRST = true

export const isLgScreen = () => {
  if (!isBrowser) {
    return !IS_MOBILE_FIRST
  }
  return window.innerWidth >= 1024
}

export const useIsLgScreen = () => {
  const [isMd, setIsMd] = useState<null | boolean>(null)

  const updateValue = () => {
    setIsMd(isMdScreen())
  }

  useEffect(() => {
    updateValue()
    window.addEventListener("resize", updateValue)

    return () => {
      window.removeEventListener("resize", updateValue)
    }
  })

  return isMd
}

export const isMdScreen = () => {
  if (!isBrowser) {
    return !IS_MOBILE_FIRST
  }
  return window.innerWidth >= 768
}

export const useIsMdScreen = () => {
  const [isMd, setIsMd] = useState<null | boolean>(null)

  const updateValue = () => {
    setIsMd(isMdScreen())
  }

  useEffect(() => {
    updateValue()
    window.addEventListener("resize", updateValue)

    return () => {
      window.removeEventListener("resize", updateValue)
    }
  })

  return isMd
}

export const isSafari = () =>
  /^((?!chrome|android).)*safari/i.test(navigator.userAgent)

const getHeaderHeight = () => {
  const header = document.getElementById("header")
  return header?.offsetHeight || 0
}

const getSectionsOnScreen = (sections: HTMLElement[]) =>
  sections.filter((el) => {
    const rect = el.getBoundingClientRect()
    const isSectionVisible = rect.bottom > 0 && rect.top < window.innerHeight

    return isSectionVisible
  })

const getCurrentSection = (sections: HTMLElement[]) => {
  return sections.find((section) => {
    const CURRENT_SECTION_POINT = window.innerHeight / 2
    const rect = section.getBoundingClientRect()

    return (
      rect.top < CURRENT_SECTION_POINT && rect.bottom > CURRENT_SECTION_POINT
    )
  })
}

export const replaceAnchorInUrlBySections = () => {
  const sections = Array.from(document.querySelectorAll("section"))

  const visibleSections = getSectionsOnScreen(sections)

  const currentSection = getCurrentSection(visibleSections)

  if (currentSection && window.history.pushState) {
    const urlAnchor = `#${currentSection.id}`

    if (window.location.hash === urlAnchor) {
      return null
    }

    window.history.replaceState(null, "", urlAnchor)

    return urlAnchor
  }

  return null
}

export const scrollToSectionId = (sectionId: string) => {
  if (!sectionId) {
    return
  }

  const elementByHash = document.getElementById(sectionId)

  if (!elementByHash) {
    console.error("section not found")
    return false
  }

  window.scrollTo({
    behavior: "smooth",
    top:
      window.scrollY +
      elementByHash.getBoundingClientRect().top -
      getHeaderHeight(),
  })
}
