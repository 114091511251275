import React, { FC } from "react"
import ContentSwitcher from "./ContentSwitcher"
import TabSelector from "./TabSelector"
import bottomShadow from "../../../assets/images/bottom-shadow.png"
import { useTranslation } from "gatsby-plugin-react-i18next"
interface IMediaSectionProps {
  className?: string
}

const MediaSection: FC<IMediaSectionProps> = ({ className }) => {
  const { t } = useTranslation("media-section")

  return (
    <section
      className={`${className} bg-mediaMobile bg-center md:bg-media bg-cover relative `}
      id="media"
    >
      <div
        id="media-anchor-desktop"
        className="veryTall:absolute veryTall:-translate-y-[50vh] veryTall:top-1/2"
      ></div>
      <div className="w-full relative pt-48 bg-black hidden md:block">
        <div className="relative w-full">
          <img
            src={bottomShadow}
            alt=""
            className="absolute -top-6 lg:-top-12 xl:-top-16 2xl:-top-24 3xl:-top-32 left-0 right-0 max-w-none w-full hidden md:block"
          />
        </div>
      </div>

      <div className="relative z-10 container mx-auto pb-24">
        <div className="text-center relative z-20">
          <TabSelector className="inline-block md:hidden" />
        </div>
        <div className="text-center mb-24">
          <TabSelector className="hidden md:inline-block" />
        </div>
        <ContentSwitcher />
      </div>
    </section>
  )
}
MediaSection.defaultProps = {
  className: "",
}

export default MediaSection
