import SectionTitle from "../../../components/ui/SectionTitle"
import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import SingleFaq from "./SingleFaq"

interface IFaqSectionProps {
  className?: string
}

const FaqSection: FC<IFaqSectionProps> = ({ className }) => {
  const { t } = useTranslation("faq-section")

  const qnaList = [
    {
      question: t("What platforms will “I, The Inquisitor” be available on?"),
      answer: t(
        `We are aiming to launch the game on Steam, PlayStation Store, and Xbox Store. Launch dates may vary depending on the platform.`
      ),
    },
    {
      question: t("What languages will the game support?"),
      answer: t(
        "“I, the Inquisitor” will have English VO and confirmed subtitles in seven languages: English, German, Italian, Spanish, French, Brazilian Portuguese, and Polish. As we want to introduce as many players from around the globe to the world of “I, the Inquisitor” more language support may be revealed in the future."
      ),
    },
    {
      question: t(`When is the game coming out?`),
      answer: t(
        `The veil of mystery is lifted. Sinners will meet their reckoning on February 8, 2024.`
      ),
    },
  ]

  const getXlClass = (index: number) => {
    if (index % 3 === 0) {
      return "xl:pr-4 xl:pl-0"
    }
    if (index % 3 === 1) {
      return "xl:pr-2 xl:pl-2"
    }
    if (index % 3 === 2) {
      return "xl:pl-2 xl:pr-0"
    }
  }

  const getLgClass = (index: number) =>
    index % 2 === 0 ? "lg:pr-2 lg:pl-0" : "lg:pl-2 lg:pr-0"

  const getListOfFaq = () =>
    qnaList.map((faq, index) => {
      return (
        <SingleFaq
          className={`mb-6 md:mb-24 w-full lg:w-1/2 xl:w-1/3 ${getLgClass(
            index
          )} ${getXlClass(index)}`}
          key={faq.question}
          question={faq.question}
          answer={faq.answer}
        />
      )
    })

  return (
    <section
      id="faq"
      className={`${className} bg-faqMobile md:bg-faq bg-no-repeat sm:bg-cover sm:bg-center overflow-hidden bg-[length:1100px_auto] bg-[49%_top] relative`}
      style={{ textShadow: "0 1px 1px #000" }}
    >
      <div
        id="faq-anchor-desktop"
        className="relative -top-32 desktop-anchor"
      ></div>
      <SectionTitle
        title={t("FAQ")}
        className="w-full relative z-10 sm:pt-16 hidden md:block"
      />
      <div className="py-32 sm:pb-48 pt-24 flex flex-wrap">
        <div
          id="faq-inner"
          className="container mx-auto flex flex-wrap justify-center mt-96 md:mt-0 z-10"
        >
          <SectionTitle
            title={t("FAQ")}
            className="w-full relative z-10 sm:pt-16 block md:hidden"
          />
          {getListOfFaq()}
        </div>
      </div>
    </section>
  )
}

FaqSection.defaultProps = {
  className: "",
}

export default FaqSection
