import { useTranslation } from "gatsby-plugin-react-i18next"
import React, { FC } from "react"
import currentGenLogos from "../../../assets/images/current-gen.png"
import currentGenLogosMobile from "../../../assets/images/current-gen-mobile.png"
import fromLeftToRightLine from "../../../assets/images/from-right-to-left-line.png"

interface IAboutGameSectionProps {
  className?: string
}

const AboutGameSection: FC<IAboutGameSectionProps> = ({ className }) => {
  const GamingPlatforms = () => (
    <>
      <p className="mb-5 text-center text-2xl md:text-base">
        {t(`"I, the Inquisitor" will be available on the following platforms:`)}
      </p>
      <img
        src={currentGenLogosMobile}
        className="mx-auto sm:hidden"
        alt="Steam, PC, XBOX X, PS5"
      />
      <img
        src={currentGenLogos}
        className="mx-auto hidden sm:block"
        alt="Steam, PC, XBOX X, PS5"
      />
    </>
  )
  const { t } = useTranslation("about-game-section")

  return (
    <section
      id="about-game"
      className={`${className} bg-aboutMobile md:bg-about bg-no-repeat py-32 sm:py-48 bg-[length:1210px_auto_,1210px_auto] bg-[49%_top_,67%_680px] md:bg-center md:bg-cover overflow-hidden relative`}
      style={{ textShadow: "0 3px 3px #000" }}
    >
      <div
        id="about-game-anchor-desktop"
        className="relative -top-44 desktop-anchor"
      ></div>
      <div className="container mx-auto flex flex-wrap md:flex-nowrap relative z-10">
        <div className="md:w-1/3">
          <h2 className="mt-96 md:mt-0 text-left md:text-right font-chamberi text-4xl md:text-5xl lg:text-6xl md:leading-relaxed lg:leading-relaxed mb-5 md:mb-1">
            {t("The Begining")}
          </h2>
          <img
            src={fromLeftToRightLine}
            alt=""
            className="rotate-180 md:rotate-0 md:mr-0 md:ml-auto"
          />
          <p className="mb-32 md:text-right mt-5 leading-tight">
            {t(
              `We've been making a fantastic game since 2019. A production based on the motifs from the series "I, the Inquisitor" by Jacek Piekara, one of Poland's most popular fantasy writers. The title character, the inquisitor of the Holy Office - Mordimer Madderdin, who cares about the new world order, will have to face brutal everyday life, and no decision will be easy. 1`
            )}
          </p>
          <div className="hidden sm:block">
            <GamingPlatforms />
          </div>
        </div>
        <div className="md:w-1/3 hidden md:block"></div>
        <div className="md:w-1/3 flex items-end flex-col-reverse">
          <div className="mt-80 md:mt-32 relative">
            <div
              className="absolute -inset-32 "
              style={{
                background:
                  "radial-gradient(circle, rgba(0,0,0,1) -60%, rgba(0,0,0,0) 50%)",
              }}
            ></div>
            <div className="relative z-10">
              <h2 className="font-chamberi text-4xl md:text-5xl lg:text-6xl md:leading-relaxed lg:leading-relaxed mb-5 md:mb-1 text-left">
                {t("Gameplay")}
              </h2>
              <img
                src={fromLeftToRightLine}
                alt=""
                className="ml-0 mr-auto rotate-180"
              />
              <p className="text-left mt-5 leading-tight">
                {t(
                  `In this story-driven game, you will investigate supernatural murder mysteries, conduct often brutal interrogations, visit the dangerous Unworld, gather clues and information from citizens in the living and breathing XVIth century town of Köenigstein. You will also encounter various enemies that will put your sword fighting skills to the test.`
                )}
              </p>
              <div className="mt-20 md:mt-0 md:hidden">
                <GamingPlatforms />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

AboutGameSection.defaultProps = {
  className: "",
}

export default AboutGameSection
