import create from "zustand"

export enum SectionTypes {
  video = "video",
  graphics = "graphics",
  others = "others",
}

interface IMediaState {
  currentSection: SectionTypes
  setCurrentSection: (sectionType: SectionTypes) => void
}

const useSideMenuStore = create<IMediaState>((set) => ({
  currentSection: SectionTypes.video,
  setCurrentSection: (sectionType: SectionTypes) =>
    set(() => ({ currentSection: sectionType })),
}))

export default useSideMenuStore
