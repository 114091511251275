import { ITranslation } from "../../../../helpers/i18n"
import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import SingleGraphicsTeaser from "./SingleGraphicTeaser"
import { useIsMdScreen } from "../../../../helpers/layout"

import { Swiper, SwiperSlide } from "swiper/react"
import { Autoplay, Pagination } from "swiper"

interface IGraphicsProps {
  className?: string
}

interface IGraphicTranslationFields {
  title: string
}

export interface IGraphic {
  id: string
  sort: number | null
  image: {
    filename_disk: string
  }
  file_to_download: {
    filename_disk: string
  } | null
  redirect_link: string
  translations: ITranslation<IGraphicTranslationFields>[]
}

interface IDirectusGraphicsData {
  directus: {
    graphics: IGraphic[]
  }
}

const Graphics: FC<IGraphicsProps> = ({ className }) => {
  const isMd = useIsMdScreen()
  const {
    directus: { graphics },
  } = useStaticQuery<IDirectusGraphicsData>(graphql`
    query {
      directus {
        graphics {
          id
          sort
          image {
            filename_disk
          }
          file_to_download {
            filename_disk
          }
          translations {
            title
            languages_code {
              code
            }
          }
        }
      }
    }
  `)

  const getGraphicsTeasers = () =>
    graphics.map((graphic) => (
      <SingleGraphicsTeaser
        key={graphic.id}
        graphic={graphic}
        className="mx-6 mt-12"
      />
    ))

  const getGraphicSlides = () =>
    graphics.map((graphic) => (
      <SwiperSlide key={graphic.id}>
        <SingleGraphicsTeaser graphic={graphic} className="mx-6 mt-12" />
      </SwiperSlide>
    ))

  const desktopVersion = () => (
    <div className={`${className}`}>
      <div className="flex justify-center flex-wrap">
        {getGraphicsTeasers()}
      </div>
    </div>
  )

  const mobileVersion = () => (
    <Swiper
      className={`${className} mb-24`}
      modules={[Autoplay, Pagination]}
      spaceBetween={50}
      autoplay={{
        delay: 5000,
      }}
      pagination={{
        el: ".media-pagination",
      }}
      breakpoints={{
        320: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        520: {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        1280: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
    >
      {getGraphicSlides()}
      <div className="media-pagination text-center flex justify-center mt-6 w-full"></div>
    </Swiper>
  )

  if (isMd || isMd === null) {
    return desktopVersion()
  }

  return mobileVersion()
}

Graphics.defaultProps = {
  className: "",
}

export default Graphics
