import React, { FC } from "react"
import bottomShadow from "../../assets/images/bottom-shadow-thin.png"
import topShadow from "../../assets/images/top-shadow-thin.png"

interface IDarkBetweenSectionsProps {
  className?: string
}

const DarkBetweenSections: FC<IDarkBetweenSectionsProps> = ({ className }) => (
  <div className="relative w-full h-1">
    <img
      src={topShadow}
      alt=""
      className="absolute inset-0 w-full -translate-y-full"
    />
    <img src={bottomShadow} alt="" className="absolute inset-0 w-full z-10" />
  </div>
)

DarkBetweenSections.defaultProps = {
  className: "",
}

export default DarkBetweenSections
