import create from "zustand"

type SideMenuStore = {
  isSideMenuOpen: boolean

  toggleSideMenu: () => void
  setIsSideMenuOpen: (value: boolean) => void
}

const sideMenuStore = create<SideMenuStore>((set) => ({
  isSideMenuOpen: false,

  toggleSideMenu: () =>
    set((state) => ({ isSideMenuOpen: !state.isSideMenuOpen })),
  setIsSideMenuOpen: (value) => set(() => ({ isSideMenuOpen: value })),
}))

export default sideMenuStore
